import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Section from "../components/Section";
import Inner from "../components/Inner";
import ContentWrapper from "../components/ContentWrapper";
import Container from "../components/Container";
import BlogPost from "../components/BlogPost";
import CtaSection from "../components/CtaSection";
import { openGraphUrlParser } from "./../common/utils";

export const BlogPostPage = ({ data }: { data: any }) => {
  const postData = data.blogPostData.posts.edges[0].node;
  const defaultSeo = data.wp.pages.edges[0].node.seo;

  const schema = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    headline: postData.title,
    image: [postData.featuredImage.mediaItemUrl],
    datePublished: postData.date,
    dateModified: postData.modifiedGmt,
  };

  const { slug, seo } = postData;

  return (
    <Layout 
      headerLocation="blog"
      footerMobileMargin={30} 
      footerBorderOnMobile={true}
    >
      <Seo
        title={`${postData.title} | Flatworld.co`}
        description={seo.metaDesc || defaultSeo.metaDesc}
        url={`/blogs/${slug}/`}
        image={
          seo.opengraphImage?.sourceUrl || defaultSeo.opengraphImage?.sourceUrl
        }
        twitterImage={
          seo.twitterImage?.sourceUrl || defaultSeo.twitterImage?.sourceUrl
        }
        schemaMarkup={schema}
      />
      <ContentWrapper isBlue>
        <Container
          zIndex={["1", "1", "auto"]}
          bg={["white", "white", "transparent"]}
          pb={["50px", "50px", "0px"]}
        >
          <Inner isBlog bg="white" isRelative isSmall>
            <Section ptBody={["20px", "25px"]}>
              <BlogPost data={postData} />
            </Section>
          </Inner>
        </Container>
        <Container pt={["220px", "180px", "0px"]} pb={["0", "86px"]}>
          <Inner noOffsetMobile>
            <Section ptBody={["", "2.5rem", "0px"]}>
              <CtaSection
                isAlt
                heading="<p>We want to help you find an <mark>amazing match</mark></p>"
                subheading="Get in touch and let's get started"
                image={["", data.ctaImage]}
                leftButtonUrl={`https://${process.env.GATSBY_PUBLIC_DOMAIN}/companies`}
                leftButtonText="Hire a great candidate"
                leftButtonTarget="_blank"
                rightButtonUrl="/jobs/"
                rightButtonText="Get hired by a great company"
              />
            </Section>
          </Inner>
        </Container>
      </ContentWrapper>
    </Layout>
  );
};

export default BlogPostPage;

export const query = graphql`
  query($id: Int) {
    blogPostData: wp {
      posts(where: { id: $id }) {
        edges {
          node {
            postId: databaseId
            id
            date
            modifiedGmt
            author {
              node {
                name
                url
              }
            }
            title
            content
            slug
            featuredImage {
              node {
                mediaItemUrl
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      width: 900
                      quality: 80
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              opengraphUrl
              opengraphImage {
                sourceUrl
              }
              twitterImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
    ctaImage: file(relativePath: { eq: "cta-person-2-img.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 320
          quality: 70
          formats: [AUTO, WEBP]
        )
      }
    }
    wp {
      pages(where: { name: "blogs" }) {
        edges {
          node {
            seo {
              title
              metaDesc
              opengraphUrl
              opengraphImage {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;
