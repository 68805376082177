import React from "react";

import {
  BlogPostStyled,
  BlogPostHeroStyled,
  BlogPostTitleStyled,
  BlogPostContentStyled,
} from "./styles";
import Wysiwyg from "./../Wysiwyg";
import Img from "./../Image";

interface IProps {
  data: any;
}

 

const BlogPost: React.FC<IProps> = ({ data }) => {
  const disableImage = data.title.indexOf('Young startup inspiring') !== -1;

  return (
    <BlogPostStyled narrow>
      {!disableImage && (
        <BlogPostHeroStyled>
          <Img
            fluid={["", data.featuredImage.node.imageFile]}
            alt={`${data.title} image`}
          />
        </BlogPostHeroStyled>
      )}
      <BlogPostTitleStyled 
        hasImage={!disableImage}
        dangerouslySetInnerHTML={{ __html: data.title }} />
      <BlogPostContentStyled>
        <Wysiwyg wide mediumType>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </Wysiwyg>
      </BlogPostContentStyled>
    </BlogPostStyled>
  );
};

export default BlogPost;
